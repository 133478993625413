.MuiDataGrid-colCellTitle { font-weight: bold !important; overflow: visible !important;color:#0046AD;font-size:1.5rem;font-family:Arial}
.MuiDataGrid-row.Mui-odd {
    background-color: lightgrey;
  }
  .adduser_button_export_wrapper {
    display: flex;
    justify-content: start;  
  }
  .adduser_button {
    color: white !important;
    border-radius: 5px !important;
    background-color: #0046AD !important;
    width: 70% !important;
    padding: 5px !important;
    font-weight: bold !important;
    font-family: Arial !important;
    border: 2px solid transparent !important;
  }
  .exportuser_button {
    color: #0046AD !important;
    border: 2px solid #0046AD !important;
    border-radius: 5px !important;
    background: white !important;
    font-weight: bold !important;
    font-family: Arial !important;
    width: 70% !important;
    padding: 5px !important;
  }
  .searchuser_wrapper {
    display: flex;
    justify-content: end;  
  }
  .searchuser_input {
    color: white;
    border-radius: 10px;
    background-color: #0046AD;
    width: 30%;
    padding: 5px;
    border: 2px solid transparent;
    margin-right: '3rem'
  }

  