.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layout {
  margin: 45px;;
}

.page-layout{
  left: 0;
  margin-left: 0!important;
  text-align: left;
}

.page-title{
  color: #0046AD;
  font-size:x-large;
  font-weight: bold;
}

.book-mark{
  right: 0;
  text-align: right;
  float: right;
  border-style: solid;
  border: width 0.1px;
  border-color:blue;
  background-color:#61dafb;
  padding: 2px 4px;
  color: blue;
}

.content-border{
  margin-top: 20px;
  border-left-style: solid;
  border-left-color: #61dafb;
  border-top-style: solid;
  border-top-color: lightgray;
  border-right-style: solid;
  border-right-color: lightgray;
  border-bottom-style:solid;
  border-bottom-color: lightgray;
  padding-left: 10px;
  margin-bottom: 10px;
  border-width: 0.5px;
  padding:10px;
}

.content-border-details{
  margin-top: 20px;
  border-left-style: solid;
  border-left-color: lightgray;
  border-top-style: solid;
  border-top-color: lightgray;
  border-right-style: solid;
  border-right-color: lightgray;
  border-bottom-style:solid;
  border-bottom-color: lightgray;
  padding-left: 10px;
  margin-bottom: 10px;
  border-width: 0.5px;
}

.content-grid{
  display: grid;
  grid-template-columns: 85% 15%; 
}

.title-bold{
  font-weight: bold;
}

.show-goal{
  text-align: center;
} 

.goal-border{
  margin-top: 5px;
  border-left-style: solid;
  border-left-color: #61dafb;
  border-top-style: solid;
  border-top-color: lightgray;
  border-right-style: solid;
  border-right-color: lightgray;
  border-bottom-style:solid;
  border-bottom-color: lightgray;
  padding: 10px 5px;
  width: 90vw;
}

.view-strategy{
  text-align:left;
}

/* .content-border{
  border-style: solid;
  border-color: lightgray;
} */

.content-grid-stategy{
  display: grid;
  grid-template-columns: 80% 20%; 
  margin-top: 10px;
}

.text-align-right{
  text-align:right;
  padding-right: 5px;
}

.content-border-Important{
  border-style: solid;
  border-color: lightgray;
  margin-top: -10px;
  border-width: 0.5px;
  border-top-width:0px;
}

.mt-20{
  margin-top: 20px;
}

.align-right{
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  justify-content:end;
  margin-top: -10px;
}

.align-right-logo{
  display: flex;
  align-items:flex-end;
  justify-content:end;
  color: green;
  margin: 5px;
}

.mb-10{
  margin-bottom: 10px;
}

.mt-10{
  margin-top: 10px;
}


 .userListHeader{color:'blue'}


